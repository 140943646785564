import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialDesignModule, SharedModule } from '../../shared';
import { TranslateModule } from '@ngx-translate/core';
import { ScoreboardComponent } from './scoreboard.component';
import { SDGComponent } from './sdg/sdg.component';
import { GRIComponent } from './gri/gri.component';
import { EUTaxonomyComponent } from './eu-taxonomy/eu-taxonomy.component';
import { ChartsModule } from 'ng2-charts';
import { ScoreboardRouting } from './scoreboard.routing';
import { EesgComponent } from './old/eesg/eesg.component';
import { ImpactComponent } from './impact/impact.component';
import { AggregatedReportComponent } from './aggregated-report/aggregated-report.component';
import { EesgChartsComponent } from './old/eesg/eesg-charts/eesg-charts.component';
import { SdgTopCardsComponent } from './sdg/sdg-top-cards/sdg-top-cards.component';
import { DownloadPdfPopUpComponent } from './download-pdf-pop-up/download-pdf-pop-up.component';
import { ResultsPopupComponent } from './results-popup/results-popup.component';
import { EesgPopupComponent } from './old/eesg/eesg-popup/eesg-popup.component';
import { CSRDComponent } from './csrd/csrd.component';
import { PreviewPopupComponent } from './preview-popup/preview-popup.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SharePopupComponent } from './share-popup/share-popup.component';
import { MultiSelectorDropdownComponent } from './filter/multi-selector-dropdown/multi-selector-dropdown.component';
import { EsgComponent } from './esg/esg.component';
import { EsgChartComponent } from './esg/chart/esg-chart.component';
import { EsgPopupComponent } from './esg/popup/esg-popup.component';
import { MatSliderModule } from '@angular/material/slider';
import { EsgOverTimeComponent } from './esg/chart/esg-over-time/esg-over-time.component';
import { ResultOverTimeComponent } from './esg/chart/result-over-time/result-over-time.component';
import { SliderGroupComponent } from './shared/slider-toggle/slider-group.component';
import { SdgPopupComponent } from './sdg/popup/sdg-popup.component';
import { SdgPopupHeaderComponent } from './sdg/popup/pop-up-header/sdg-popup-header.component';
import { GriPopupComponent } from './gri/popup/gri-popup.component';
import { CsrdMaterialityChartComponent } from './csrd/materiality-chart/csrd-materiality-chart.component';
import { CsrdFramePopupComponent } from './csrd/popup/frame/csrd-frame-popup.component';
import { CsrdDataPointPopupComponent } from './csrd/popup/datapoint/csrd-datapoint-popup.component';
import { CsrdAxisPopupComponent } from './csrd/popup/axis/csrd-axis-popup.component';

@NgModule({
  declarations: [
    ScoreboardComponent,
    SDGComponent,
    GRIComponent,
    EUTaxonomyComponent,
    EesgComponent,
    EsgComponent,
    ImpactComponent,
    AggregatedReportComponent,
    EesgChartsComponent,
    EsgChartComponent,
    SdgTopCardsComponent,
    DownloadPdfPopUpComponent,
    ResultsPopupComponent,
    EesgPopupComponent,
    EsgPopupComponent,
    CSRDComponent,
    PreviewPopupComponent,
    SharePopupComponent,
    MultiSelectorDropdownComponent,
    SliderGroupComponent,
    EsgOverTimeComponent,
    ResultOverTimeComponent,
    SdgPopupComponent,
    SdgPopupHeaderComponent,
    GriPopupComponent,
    CsrdMaterialityChartComponent,
    CsrdFramePopupComponent,
    CsrdDataPointPopupComponent,
    CsrdAxisPopupComponent,
  ],
  imports: [
    ScoreboardRouting,
    MaterialDesignModule,
    TranslateModule,
    CommonModule,
    SharedModule,
    ChartsModule,
    PdfViewerModule,
    MatSliderModule,
  ],
  exports: [
    GRIComponent,
    EesgChartsComponent,
    MultiSelectorDropdownComponent,
    SliderGroupComponent,
    CsrdMaterialityChartComponent,
  ],
})
export class ScoreboardModule {}
